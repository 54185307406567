<template>
    <div>

        <v-simple-table light class="elevation-1 table-light-striped">
            <template v-slot:default>
                <thead>
                <tr class="rfnavy">
                    <th align="left" colspan="7">
                        <h3 class="title font-weight-light white--text">National Net System
                        Schedule</h3>
                    </th>
                </tr>
                <tr class="rfnavy">
                    <th align="left" class="white--text subtitle-1">Day</th>
                    <th align="left" class="white--text subtitle-1">Pacific</th>
                    <th align="left" class="white--text subtitle-1">Arizona</th>
                    <th align="left" class="white--text subtitle-1">Mountain</th>
                    <th align="left" class="white--text subtitle-1">Central</th>
                    <th align="left" class="white--text subtitle-1">Eastern</th>
                    <th align="left" class="white--text subtitle-1">Net</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="net in nets" :key="net.name">
                    <td>{{ net.time.format('dddd') }}</td>
                    <td>{{ net.time.clone().tz('America/Los_Angeles').format('h:mm A') }}</td>
                    <td>{{ net.time.clone().tz('America/Phoenix').format('h:mm A') }}</td>
                    <td>{{ net.time.clone().tz('America/Denver').format('h:mm A') }}</td>
                    <td>{{ net.time.clone().tz('America/Chicago').format('h:mm A') }}</td>
                    <td>{{ net.time.clone().tz('America/New_York').format('h:mm A') }}</td>
                    <td>{{ net.name }}</td>
                </tr>
                <tr>
                    <td colspan="7">
                        * Arizona does not observe Daylight Savings Time so it will either align with Pacific or
                        Mountain time depending on the time of year
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<style>
    .table-light-striped tr:nth-child(even) {
        /*background-color: var(--v-rfnavy-lighten4);*/
        background-color: #F6F6F6;
    }
</style>

<script>
    import moment from 'moment-timezone';

    export default {
        data: () => ({
            nets: [
                {
                    name: 'Midwest Regional Net',
                    time: moment.tz('America/New_York').day('Wednesday').hour(20).minute(0).second(0).millisecond(0)
                },
                {
                    name: 'Southwest Regional Net',
                    time: moment.tz('America/New_York').day('Sunday').hour(19).minute(0).second(0).millisecond(0)
                },
                {
                    name: 'Southern Plains Regional Net',
                    time: moment.tz('America/New_York').day('Sunday').hour(19).minute(0).second(0).millisecond(0)
                },
                {
                    name: 'Eastern Regional Net',
                    time: moment.tz('America/New_York').day('Sunday').hour(19).minute(0).second(0).millisecond(0)
                },
                {
                    name: 'National Net',
                    time: moment.tz('America/New_York').day('Sunday').hour(20).minute(0).second(0).millisecond(0)
                },
            ]
        }),

        mounted() {

        }
    }
</script>
